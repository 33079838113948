import React from 'react';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import { Formik } from 'formik';
import { Editor } from '@tribiahq/interaxo-react-components';

import { getSelectFieldOptions, getInputProps } from '../../utils/FieldUtil';
import SubscriptionContainer from '../../common/SubscriptionContainer';
import { validateRequiredFields, validateEntryTitleField } from '../../utils/Validators';
import { renderField, renderSelectField, renderSwitchField } from '../../utils/RenderUtil';
import DatePicker from '../../common/DatePicker';
import StopClickPropagation from '../../common/StopClickPropagation';
import Value from '../../common/Value';
import SplitButton from '../../common/SplitButton';

const styles = theme => ({
  field: {
    width: 540,
  },
  textField: {
    width: 540,
    marginBottom: 15,
  },
  dateField: {
    width: 300,
    marginBottom: 15,
  },
  listField: {
    width: 300,
    marginBottom: 15,
  },
  listItem: {
    minHeight: 36,
  },
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  header: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  metaInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '70%',
  },
  column: {
    verticalAlign: 'bottom',
    marginLeft: '2%',
  },
  title: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
});

class Form extends React.Component {
  state = {
    initializedEditors: {},
    closeOnSubmit: false,
  };

  handleFocus = id => {
    this.setState({ [id]: true });
  };

  closeEditor = id => {
    this.setState({ [id]: false });
  };

  validate = values => {
    const { requiredFields, fieldConfig } = this.props;
    let errors = {};
    validateRequiredFields({ requiredFields, values, errors });
    validateEntryTitleField({ fieldConfig, values, errors });
    return errors;
  };

  fetchResponsible = values => {
    this.props.onFetchPossibleResponsible({ disciplines: values });
  };

  getFieldDef = ({ fieldConfig, fieldId }) => {
    return fieldConfig.fields.filter(def => def.id === fieldId)[0];
  };

  taskDone = ({ field, values, disabled, handleChange, handleBlur, getFieldError }) => {
    return [
      renderSwitchField({
        name: field.id,
        label: field.name,
        value: values[field.id],
        disabled,
        handleChange,
        handleBlur,
        error: getFieldError({
          id: field.id,
        }),
      }),
    ];
  };

  taskResponsible = ({ field, values, disabled, handleChange, handleBlur, getFieldError }) => {
    return [
      renderSelectField({
        name: field.id,
        label: field.name,
        value: values[field.id] || '',
        disabled: this.props.possibleResponsible
          ? disabled || this.props.possibleResponsible.length === 0
          : disabled,
        handleChange,
        handleBlur,
        error: getFieldError({
          id: field.id,
        }),
        options: [''].concat(this.props.possibleResponsible || []),
        className: this.props.classes.listField,
        itemStyle: this.props.classes.listItem,
      }),
    ];
  };

  listField = ({
    field,
    values,
    disabled,
    handleChange,
    handleBlur,
    getFieldError,
    setFieldValue,
  }) => {
    return [
      renderSelectField({
        name: field.id,
        label: field.name,
        value: values[field.id] || '',
        disabled,
        handleChange: e => {
          // If responsible field is present the possible values should
          // be the members of the disciplines selected in "TO"
          if (
            this.props.onFetchPossibleResponsible &&
            field.id === this.props.fieldConfig.to_member_field
          ) {
            this.props.onFetchPossibleResponsible({
              disciplines: e.target.value,
            });
            setFieldValue('phentry:taskResponsible', []);
          }
          handleChange(e);
        },
        handleBlur,
        error: getFieldError({
          id: field.id,
        }),
        options: getSelectFieldOptions({
          field,
          fieldConfig: this.props.fieldConfig,
          values,
        }),
        className: this.props.classes.listField,
        itemStyle: this.props.classes.listItem,
        multiple: this.getFieldDef({
          fieldConfig: this.props.fieldConfig,
          fieldId: field.id,
        }).multiple,
      }),
    ];
  };

  dateField = ({
    field,
    values,
    disabled,
    getFieldError,
    setFieldValue,
    disablePast,
    disableFuture,
  }) => {
    return [
      <DatePicker
        key={`field-${field.id}`}
        field={field}
        value={values[field.id]}
        handleChange={setFieldValue}
        disabled={disabled}
        error={getFieldError({ id: field.id })}
        disablePast={disablePast}
        disableFuture={disableFuture}
        className={this.props.classes.dateField}
      />,
    ];
  };

  richTextField = ({ field, values, disabled, handleBlur, getFieldError, setFieldValue }) => {
    const id = field.id;
    return [
      <Editor
        id={id}
        label={field.name}
        includeLabel
        value={values[id]}
        errorText={getFieldError({ id: field.id })}
        style={{ marginTop: 0, marginBottom: 0 }}
        width={540}
        height={this.state[id] ? 280 : 160}
        onChange={(event, editor) => {
          // Temp fix for DARWIN-19246
          // As this onChange is triggered at initialization, when there has been no user input
          // and there should be no changes, skip setting any value at this point to avoid the form
          // being marked as dirty.
          // todo: replace with Object.prototype.hasOwnProperty.call(this.state, field.id)?
          // eslint-disable-next-line no-prototype-builtins
          if (!this.state.initializedEditors.hasOwnProperty(field.id)) {
            this.setState({ initializedEditors: { [field.id]: true } });
          } else {
            setFieldValue(id, editor.getContent());
          }
        }}
        disabled={disabled}
        onFocus={() => {
          this.handleFocus(id);
        }}
        key={`field-${id}-RTE`}
      />,
    ];
  };

  defaultField = ({
    field,
    values,
    disabled,
    handleChange,
    handleBlur,
    getFieldError,
    touched,
    ...other
  }) => {
    return renderField({
      name: field.id,
      label: field.name,
      value: values[field.id],
      disabled: disabled || field.type === 'auto-number',
      handleChange,
      handleBlur,
      error:
        touched[field.id] &&
        getFieldError({
          id: field.id,
        }),
      className: this.props.classes.textField,
      ...getInputProps({
        field,
        fieldConfig: this.props.fieldConfig,
        values,
      }),
      ...other,
    });
  };

  renderFieldMap = {
    'task-done': this.taskDone,
    'task-responsible': this.taskResponsible,
    'rich-text': this.richTextField,
    'auto-number': this.defaultField,
    'unique-document-id': this.defaultField,
    'sequence-number': this.defaultField,
    member: this.listField,
    list: this.listField,
    date: this.dateField,
    string: this.defaultField,
    numeric: this.defaultField,
  };

  isFieldDisabled = (disabledFields, values, fieldId) => {
    const { fieldConfig, formValidation } = this.props;
    let key = Object.keys(formValidation).find(key => fieldConfig[key] === fieldId);

    return key
      ? disabledFields.includes(fieldId) || !formValidation[key](fieldConfig, values)
      : disabledFields.includes(fieldId);
  };

  /**
   * Builds the form according the type of the Board.
   * @param  {object} card         Current card beeing edited/created
   * @param  {func} handleChange handleChange from Formik
   * @param  {object} values       form values from Formik
   * @param  {func} handleBlur   handleBlur from Formik
   * @param  {object} errors       form errors from Formik
   * @return {[type]}              [description]
   */
  buildFormItems = ({
    item,
    errors,
    values,
    disabledFields,
    handleChange,
    handleBlur,
    touched,
    setFieldValue,
  }) => {
    const { type, classes, restrictions, fieldConfig } = this.props;

    let fields = item.fields;
    if (!item.id) {
      fields = fields.filter(field => field.type !== 'task-done');
    }

    if ('QUESTION' === type) {
      const getFieldError = ({ id }) => errors[id];
      return (
        <div key={`f-${item.id}`} className={classes.container}>
          {fields.map(field =>
            this.renderFieldMap[field.type]({
              field,
              values,
              disabled: this.isFieldDisabled(disabledFields, values, field.id),
              handleChange,
              handleBlur,
              getFieldError,
              touched,
              setFieldValue,
              ...restrictions[field.type](fieldConfig, field),
            }),
          )}
        </div>
      );
    }
    // Other types
    return null;
  };

  handleSaveClick = closeForm => {
    const form = this.formRef.current;

    this.setState({ closeOnSuccess: !!closeForm });

    if (form) {
      form.handleSubmit();
    }
  };

  render() {
    const {
      formRef,
      classes,
      item,
      disabledFields,
      initialValues,
      onCancel,
      deleteIcon,
      metaInfo,
      submitOptions,
    } = this.props;

    return (
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        onSubmit={async values =>
          await this.props.onSubmit({
            values,
            item: this.props.item,
            closeOnSuccess: this.state.closeOnSubmit,
          })
        }
        validate={this.validate}
        enableReinitialize={true}
        render={({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          setFieldValue,
          handleSubmit,
          isSubmitting,
          isValid,
          dirty,
        }) => (
          <form onSubmit={handleSubmit}>
            <ExpansionPanel defaultExpanded>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <div className={classes.header}>
                  <Typography className={classes.heading}>
                    {intl.get('common.form.info')}
                  </Typography>
                  <StopClickPropagation>
                    {deleteIcon}
                    {item.id && <SubscriptionContainer id={item.id} />}
                  </StopClickPropagation>
                </div>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                {this.buildFormItems({
                  item,
                  disabledFields,
                  errors,
                  values,
                  handleChange,
                  handleBlur,
                  touched,
                  setFieldValue,
                })}
              </ExpansionPanelDetails>
              <Divider />
              <ExpansionPanelActions
                style={{
                  justifyContent: metaInfo ? 'space-between' : 'flex-end',
                }}>
                {metaInfo && (
                  <div className={classes.metaInfo}>
                    <div className={classes.column}>
                      <Value
                        caption={intl.get('card.owner')}
                        value={metaInfo.owner}
                        classes={{ caption: classes.title }}
                      />
                    </div>
                    <div className={classes.column}>
                      <Value
                        caption={intl.get('card.created.by')}
                        value={metaInfo.createdBy}
                        classes={{ caption: classes.title }}
                      />
                      <Value
                        caption={intl.get('card.created')}
                        value={metaInfo.creationDate}
                        classes={{ caption: classes.title }}
                      />
                    </div>
                    <div className={classes.column}>
                      <Value
                        caption={intl.get('card.changed.by')}
                        value={metaInfo.modifiedBy}
                        classes={{ caption: classes.title }}
                      />
                      <Value
                        caption={intl.get('card.changed')}
                        value={metaInfo.modifiedDate}
                        classes={{ caption: classes.title }}
                      />
                    </div>
                  </div>
                )}
                <div style={{ display: 'flex' }}>
                  {!isSubmitting && (
                    <Button size="small" onClick={onCancel}>
                      {intl.get('common.form.back')}
                    </Button>
                  )}
                  <SplitButton
                    disabled={!isValid || !dirty || isSubmitting}
                    isLoading={isSubmitting}
                    options={submitOptions}
                  />
                </div>
              </ExpansionPanelActions>
            </ExpansionPanel>
          </form>
        )}
      />
    );
  }
}

Form.propTypes = {
  formRef: PropTypes.node,
  classes: PropTypes.shape({
    field: PropTypes.string,
    textField: PropTypes.string,
    dateField: PropTypes.string,
    listField: PropTypes.string,
    container: PropTypes.string,
    header: PropTypes.string,
    heading: PropTypes.string,
    metaInfo: PropTypes.string,
    column: PropTypes.string,
    title: PropTypes.string,
    listItem: PropTypes.string,
  }).isRequired,
  theme: PropTypes.shape({}).isRequired,
  restrictions: PropTypes.shape({}),
  formValidation: PropTypes.shape({}),
  type: PropTypes.string,
  onDelete: PropTypes.func,
  onCancel: PropTypes.func,
  onOpenDialog: PropTypes.func,
  onFetchPossibleResponsible: PropTypes.func,
  onSubmit: PropTypes.func,
  item: PropTypes.shape({
    id: PropTypes.string,
    owner: PropTypes.string,
  }).isRequired,
  possibleResponsible: PropTypes.arrayOf(PropTypes.shape({})),
  requiredFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  initialValues: PropTypes.shape({}).isRequired,
  disabledFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  fieldConfig: PropTypes.shape({
    to_member_field: PropTypes.string.isRequired,
  }),
  deleteIcon: PropTypes.node,
  metaInfo: PropTypes.shape({
    owner: PropTypes.string,
    creationDate: PropTypes.string,
    createdBy: PropTypes.string,
    modifiedDate: PropTypes.string,
    modifiedBy: PropTypes.string,
  }),
  submitOptions: PropTypes.arrayOf(PropTypes.shape({})),
};

Form.defaultProps = {
  restrictions: {
    'task-done': () => ({}),
    'task-responsible': () => ({}),
    'rich-text': () => ({}),
    'auto-number': () => ({}),
    'unique-document-id': () => ({}),
    'sequence-number': () => ({}),
    member: () => ({}),
    list: () => ({}),
    date: () => ({}),
    string: () => ({}),
    numeric: () => ({}),
  },
  formValidation: {
    answered_date_field: () => ({}),
  },
  onDelete: () => {},
  onCancel: () => {},
  onFetchPossibleResponsible: () => {},
  onSubmit: () => {},
  possibleResponsible: [],
  metaInfo: null,
  submitOptions: [],
};

export default withStyles(styles, { withTheme: true })(Form);
