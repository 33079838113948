import React from 'react';
import Value from '../../common/Value';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const styles = theme => ({
  lightTooltip: {
    background: theme.palette.common.white,
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[1],
  },
  container: {
    marginRight: 8,
    width: 300,
    overflow: 'hidden',
  },
});

class CardItemTooltip extends React.Component {
  render() {
    const { viewConfig, classes, map } = this.props;

    return (
      <Tooltip
        title={
          <div className={classes.container}>
            {map.map(item => (
              <Value
                key={'tooltip-item-' + item.key}
                highReadability={viewConfig.highReadability}
                caption={item.key}
                value={
                  item.type === 'rich-text' ? (
                    <div dangerouslySetInnerHTML={{ __html: item.value }} />
                  ) : (
                    item.value
                  )
                }
              />
            ))}
          </div>
        }
        classes={{ tooltip: classes.lightTooltip }}>
        {this.props.children}
      </Tooltip>
    );
  }
}

CardItemTooltip.propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object, PropTypes.string]), // from 'withStyles'
  viewConfig: PropTypes.object.isRequired,
  map: PropTypes.array.isRequired,
  children: PropTypes.any,
};

CardItemTooltip.defaultProps = {
  children: null,
};

export default withStyles(styles)(CardItemTooltip);
